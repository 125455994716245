import PropTypes from 'prop-types';
import React, { cloneElement, useState, useEffect } from 'react';

import useClassy from '@core/hooks/useClassy';

import Button from '@ui/Button';
import Dropdown from '@ui/Dropdown';

import classes from './style.module.scss';

function ButtonGroup({
  animateTransition = false,
  className = '',
  circular,
  children,
  highlightSelection = true,
  selectedKey,
  style = {},
}) {
  const [selectedButton, setSelectedButton] = useState(selectedKey);
  const bem = useClassy(classes, 'ButtonGroup');

  useEffect(() => {
    if (selectedKey) setSelectedButton(selectedKey);
  }, [selectedKey]);

  // No point in making a group for less than 2 buttons
  if (children.length < 2) return children;

  const hasDropdown = children[0].type === Dropdown;

  return (
    <div
      className={bem(
        '&',
        hasDropdown && '_dropdown',
        circular && '_circular',
        animateTransition && '_animated',
        className,
      )}
      style={style}
    >
      {children.map(child => {
        // Cloning child components with special props only makes sense when the
        // child is a Button component. Otherwise, the props we're passing along
        // in the cloned element are probably invalid. For example, if they are
        // Tooltip components instead, Tippy will warn you that "onClick",
        // "kind" and "type" are invalid properties.
        if (child.type !== Button && child.type !== Dropdown) return child;

        // Dropdown buttons are a special case
        if (child.type === Dropdown) {
          const dropdownButtonChild = child.props.children[0];
          const isSelected = selectedButton === child.key;

          const modifiedDropdownButtonChild = cloneElement(dropdownButtonChild, {
            ...dropdownButtonChild.props,
            onClick: e => {
              if (highlightSelection) {
                // If the key is prop-controlled (i.e. passed in), don't allow deselection of the button
                setSelectedButton(isSelected && !selectedKey ? null : child.key);
              }
              if (typeof dropdownButtonChild.props?.onClick === 'function') {
                dropdownButtonChild.props.onClick(e, child.key);
              }
            },
            kind: isSelected ? 'primary' : 'secondary',
            type: 'button',
          });

          // Clone the Dropdown, replacing its first child with the modified button
          return cloneElement(child, {
            ...child.props,
            key: child.key,
            children: [modifiedDropdownButtonChild, ...child.props.children.slice(1)],
          });
        }

        const isSelected = selectedButton === child.key;

        return cloneElement(child, {
          key: child.key,
          className: child.props.className ?? '',
          onClick: e => {
            if (highlightSelection) {
              // If the key is prop-controlled (i.e. passed in), don't allow deselection of the button
              setSelectedButton(isSelected && !selectedKey ? null : child.key);
            }
            if (typeof child.props?.onClick === 'function') child.props.onClick(e, child.key);
          },
          kind: isSelected ? 'primary' : 'secondary',
          type: 'button',
          circular: circular || child.props.circular,
        });
      })}
    </div>
  );
}

ButtonGroup.propTypes = {
  animateTransition: PropTypes.bool,
  circular: PropTypes.bool,
  className: PropTypes.string,
  highlightSelection: PropTypes.bool,
  /** Passing a selectedKey essentially makes this a controlled component */
  selectedKey: PropTypes.string,
};

export default ButtonGroup;
