import PropTypes from 'prop-types';
import React from 'react';

import useEnvInfo from '@core/hooks/useEnvInfo';

import Badge from '@ui/Badge';

const SuggestionStatus = ({ merged, closed } = {}) => {
  const { isServer } = useEnvInfo();

  if (isServer) return null;

  const color = closed ? 'failure' : merged ? 'callout' : 'success';
  const status = closed ? 'Closed' : merged ? 'Merged' : 'Open';

  return <Badge kind={color}>{status}</Badge>;
};

SuggestionStatus.propTypes = {
  closed: PropTypes.bool,
  merged: PropTypes.bool,
};

export default SuggestionStatus;
